import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import CustomerForm from '../form';

class EstatePlanning extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render () {
        return(
            <>
                <section className="practice-header" id="estate-planning-header">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col>
                                <h4>ESTATE PLANNING</h4>
                                <p className="practice-quote">“I need an estate plan.”</p>
                                <div>
                                    <h6>Wills</h6>
                                    <p>A last will and testament is a legal document that communicates a person's final wishes pertaining to possessions and dependents. A person's last will and testament outlines what to do with possessions, whether the deceased will leave them to another person, a group or donate them to charity, and what happens to other things he or she is responsible for, such as custody of dependents, and management of accounts and interests.</p>
                                </div>
                                <div>
                                    <h6>Trusts</h6>
                                    <p>A trust is a fiduciary relationship in which one party, known as a trustor, gives another party, the trustee, the right to hold title to property or assets for the benefit of a third party, the beneficiary. Trusts are established to provide legal protection for the trustor’s assets, to make sure those assets are distributed according to the wishes of the trustor, and to save time, reduce paperwork and, in some cases, avoid or reduce inheritance or estate taxes</p>
                                    <div>
                                        <p>What are the benefits of a Trust?</p>
                                        <ul>
                                            <li>Some of the common benefits of a trust include reduced estate taxes, allocation of assets into the desired hands, protection from creditors, or even protection of assets among family members themselves (for conflicts or underage recipients). You are protecting your wealth and financial legacy, but more importantly you have an opportunity to give back to your loved ones in a beneficial way. Because some common types of trusts help you avoid probate court, the assets within your trust may be able to get to beneficiary faster.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <h6>Powers of Attorney</h6>
                                    <p>A power of attorney is a document you can use to appoint someone to make decisions on your behalf. The person you designate is called an "attorney-in-fact." The appointment can be effective immediately or can become effective only if you are unable to make decisions on your own, usually financial and health care decisions.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="form-section">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col>
                                <CustomerForm />
                            </Col>
                        </Row>
                    </Container>  
                </section>
            </>
        )
    }
}

export default EstatePlanning;