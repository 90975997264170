import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import CustomerForm from '../form';

class PersonalInjury extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render () {
        return(
            <>
                <section className="practice-header" id="guardianship-header">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col>
                                <h4>PERSONAL INJURY</h4>
                                <p className="practice-quote">“I’ve just been in an accident.”</p>
                                <p>Every case is different, and there is no one way to handle a personal injury matter. That said, below is a general overview of some of the steps in a personal injury case.</p>
                                <div>
                                    <h6>Step 1  - Investigation of your case</h6>
                                    <p>Your attorney should fully and independently investigate your personal injury claim, including police reports, the accident scene, photographs, witness testimony, medical records and bills, your employment history and earning power, and more. When appropriate, your attorney should collaborate with accident reconstruction experts, medical experts and other experts in a variety of fields depending on the circumstances of your accident and injuries. Your attorney should also examine the possible defenses of the opposing party and determine liability.</p>
                                </div>
                                <div>
                                    <h6>Step 2  - Demand package</h6>
                                    <p>This step involves a demand for settlement, including a demand letter outlining your case, including liability and damages (injuries, medical bills, lost wages, pain and suffering, loss of life’s enjoyment and future medical care). The letter is then often reviewed by the opposing party, be it an individual, business and/or insurance company. In response to the letter, the opposing party will generally either reject the demand, make a counteroffer or accept it.  </p>
                                </div>
                                <div>
                                    <h6>Step 3 - Filling a personal injury lawsuit</h6>
                                    <ul>
                                        <li>If your case cannot be settled up front, your attorney may work with you to file a lawsuit seeking compensation.</li>
                                        <li>This step can include: Discovery, Mediation, Trial, and an Appeal. </li>
                                        <li>Most often the case will be settled through negotiation of the demand package before the filing of a personal injury lawsuit. A case can also be settled any time after filing of a lawsuit, but before trial.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="form-section">
                    <Container className="mx-auto p-4">
                    <Row>
                        <Col>
                            <CustomerForm />
                        </Col>
                    </Row>
                </Container>
                </section>
            </>
        )
    }
}

export default PersonalInjury;