import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Office from "../assets/Office.png";
import LawyerJohn from "../assets/JOHNG.webp";
import LawyerCourt from "../assets/COURTLAND.webp";
import LawyerLindsay from "../assets/Lindsay.png";
// import LawyerIan from "../assets/Ian.png";
import Diane from "../assets/Diane.webp";
//import Marisa from "../assets/IanIan74f0f962.webp";
import Marisa from "../assets/Marisa.webp";

class Firm extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <section id="firm-header">
          <Container className="re-order mx-auto p-4">
            <h5 className="section_header">MEET OUR FIRM</h5>
            <Row>
              <Col xs={12} md={6} className="first">
                <div title="primary focus of our office" id="firm_f">
                  <p id="firm_focus">
                    The primary focus of our office is Elder Law, which covers a
                    broad spectrum of services, including but not limited to
                    probate, estate planning, vulnerable adult protection
                    actions, guardianship issues, and other general needs of an
                    aging population. Discussing planning and end of life issues
                    can be difficult, but we strive to conduct these matters in
                    a professional, friendly, and comforting manner.{" "}
                  </p>
                </div>
                <div title="OUR AFFILIATIONS" id="firm_aff">
                  <h6>OUR AFFILIATIONS</h6>
                  <p>Washington State Bar Association</p>
                  <p>King County Bar Association</p>
                  <p>American Bar Association</p>
                </div>
              </Col>
              <Col xs={12} md={6} className="second">
                <div title="Our office">
                  <img id="office_photo" alt="office" src={Office} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="lawyer-section">
          <Container className="mx-auto p-4">
            <Row>
              <Col xs={12} md={4}>
                <img
                  src={LawyerJohn}
                  alt="JOHN G. LLEWELLYN"
                  className="float-right"
                />
              </Col>
              <Col xs={12} md={8} className="lawyer-individual">
                <div title="JOHN G. LLEWELLYN" className="lawyer-heading">
                  <h5>JOHN G. LLEWELLYN</h5>
                  <h6>Principal Attorney</h6>
                  <a href="mailto:John@llllaw.net">John@llllaw.net</a>
                </div>
                <p>
                  Since 2005, Llewellyn & Shafer have focused on Estate
                  Planning, Probate, Guardianship, Elder Law, Real Estate and
                  Business Entities and Partnerships. With the welcome addition
                  of Courtland Shafer, we expanded into Litigation, Personal
                  Injury, Financial Regulatory Issues, and other contested
                  matters, including Wills and Probates. We love what we do,
                  helping people at difficult times in their lives, and helping
                  people avoid problems by planning for their loved ones before
                  they have needs.
                </p>
                <p>
                  Everyone should make an estate plan to assure their wishes are
                  met. However, if you or your loved one don’t get around to
                  making arrangements for your estate, the State of Washington
                  will take care of it. But they really won’t talk to you about
                  it, so that’s where we can help.
                </p>
                <p>
                  We provide estate planning services, from simple to complex.
                  We enjoy serving Senior Citizens, Veterans, and for that
                  matter, anyone of any age, working personally with clients to
                  determine what their needs, and how those needs can be met. We
                  look closely for any potential issues that might arise for all
                  clients, regardless of their initial requests and needs, to
                  make sure client concerns and unanticipated issues are fully
                  addressed. Through email, we are available most of the time,
                  and clients are often surprised to get responses from us in
                  the wee hours of the morning. Guardianships are one of our
                  areas of expertise. We serve our clients and the Court as
                  Court Visitors, (formerly Guardian ad Litem – GAL) for Alleged
                  Incapacitated Persons (AIP), and vigorously advocate for AIPs.
                  When necessary, we file Petitions for Guardianship for AIPs,
                  working closely with the Courts and with the State of
                  Washington Attorney General’s offices of Adult Protective
                  Services – APS, and other State and Local agencies. We are
                  passionate about protecting Vulnerable Adults from abuse.
                </p>
                <p>
                  I serve as a Court Visitor, Probate Guardian ad Litem, and as
                  a Settlement Guardian ad Litem. In those roles, and when
                  advocating for incapacitated people, we strive to ensure that
                  any Alleged Incapacitated Person (including anyone under the
                  age of 18) receives the maximum benefit they are entitled to,
                  including Personal Injury, Inheritance and Government
                  benefits.
                </p>
                {/* <p>
                  I serve as a Probate Guardian ad Litem, and as a Settlement
                  Guardian ad Litem. In those roles, and when I advocate for an
                  AIP, I strive to ensure that any Alleged Incapacitated Person
                  (including anyone under the age of 18) receives the maximum
                  benefit they are entitled to, including Personal Injury,
                  Inheritance and Government benefits.
                </p> */}
                <p>
                  We provide pro bono services at Senior Centers throughout the
                  Puget Sound area, and volunteer with several local charities
                  and foundations.
                </p>
                <p>
                  I am a fourth-generation Seattle resident, have attended 4 of
                  Washington’s colleges and universities (broken up by military
                  service from 1972 to 1974). I’m a proud and honorably
                  discharged veteran of the United States Army and financed my
                  education with the GI Bill.
                </p>
                {/* <p>
                  For my pleasure, I practice law. For my leisure, I’m an Alpine
                  and Nordic skier, sailor, gardener, grape farmer and
                  winemaker. My wife and I love to travel, and spoil our 27
                  nieces and nephews, and 16 (and counting) great nieces and
                  nephews. I’ve also participated, with multiple generations of
                  my family in 30 and counting Great Figgy Pudding Caroling
                  Competitions as the ‘Llew Crew,’ where I hold the honorary
                  title of “Big Fig.”
                </p> */}
              </Col>
            </Row>
            <hr className="lawyer-divider"></hr>
            <Row>
              <Col xs={12} md={4}>
                <img
                  src={LawyerCourt}
                  alt="COURTLAND T. SHAFER"
                  className="float-right"
                />
              </Col>
              <Col xs={12} md={8} className="lawyer-individual">
                <div title="COURTLAND T. SHAFER" className="lawyer-heading">
                  <h5>COURTLAND T. SHAFER</h5>
                  <h6>Partner</h6>
                  <a href="mailto:Courtland@llllaw.net">Courtland@llllaw.net</a>
                </div>
                <p>
                  I started practicing law in 1992 and have experience in a wide
                  range of legal fields. My practice focuses on litigation and
                  includes trust, estate, and probate disputes, personal injury
                  claims, investment fraud, federal practice, appellate
                  practice, dispute resolutions, and securities.
                </p>
                <p>
                  With over twenty-five years in the legal field, I’ve had the
                  opportunity to represent a wide variety of clients in numerous
                  areas of law, including bank transactions, financial advice,
                  and corporate matters. However, my preference is assisting
                  clients with personal matters involving trusts, probates, and
                  estate conflicts.
                </p>
                <p>
                  <span className="lawyer-bold">
                    Year Joined Organization:{" "}
                  </span>
                  2014
                </p>
                <p>
                  <span className="lawyer-bold">Areas of Practice:</span>
                </p>
                <ul>
                  <li>Investor Claims</li>
                  <li>Wealth Transfer</li>
                  <li>Litigation</li>
                </ul>
                <p>
                  <span className="lawyer-bold">Litigation Percentage: </span>
                  80% of Practice Devoted to Litigation
                </p>
                <p>
                  <span className="lawyer-bold">Bar Admissions: </span>
                  Washington, 1992
                </p>
                <p>
                  <span className="lawyer-bold">Education:</span>
                </p>
                <ul>
                  <li>
                    University of Washington School of Law, Seattle, Washington,
                    June, 1992{" "}
                  </li>
                  <li>J.D.</li>
                  <li>University of Washington, June, 1989</li>
                  <li>B.A.</li>
                  <ul>
                    <li>Honors: Awarded Membership in Omicron Delta Epsilon</li>
                    <li>Honors: Economics Honor Society, 1989</li>
                    <li>Major: Political Science</li>
                    <li>Major: Economics</li>
                  </ul>
                </ul>
                <p>
                  <span className="lawyer-bold">Representative Case: </span>
                </p>
                <p>Gilbert H. Moen Co. v Island Steel, 128 Wn. 2d 745 (1996)</p>
              </Col>
            </Row>
            <hr className="lawyer-divider"></hr>
            <Row>
              <Col xs={12} md={4}>
                <img
                  src={Diane}
                  alt="Dianlyn G. Cenidoza"
                  className="float-right"
                />
              </Col>
              <Col xs={12} md={8} className="lawyer-individual">
                <div title="Dianlyn G. Cenidoza" className="lawyer-heading">
                  <h5>Dianlyn G. Cenidoza</h5>
                  <h6>Associate Attorney</h6>
                  <a href="mailto:diane@llllaw.net">diane@llllaw.net</a>
                </div>
                <p>
                  Dianlyn G. Cenidoza is an associate attorney at the firm.
                  Diane first joined the firm in 2019 as an intern during her
                  second year of law school. She is licensed in California and
                  Washington. Diane’s practice includes estate planning,
                  probate, contracts, business formation, and litigation.
                </p>
                <p>
                  She has assisted clients on a variety of matters, including
                  TEDRA (Trust and Estate Dispute Resolution Act) matters (e.g.,
                  will contests, breach of fiduciary duty claims, beneficiary
                  claims, and etc.), guardianship, contract disputes, personal
                  injury, real estate, and landlord/tenant issues.
                </p>
                <p>
                  On her free time, Diane enjoys spending time with her dog
                  (Tiggie), traveling, eating, pilates, working out, singing to
                  Taylor Swift songs, attending concerts, and playing the
                  guitar.
                </p>
                <p>
                  <span className="lawyer-bold">Bar Admissions: </span>
                  <ul>
                    <li>Washington, 2021</li>
                    <li>California, 2021</li>
                  </ul>
                </p>
                <p>
                  <span className="lawyer-bold">Education:</span>
                </p>
                <ul>
                  <li>
                    <b>Seattle University School of Law,</b> J.D., 2020
                  </li>
                  <li>
                    Research & Technical Editor, Seattle University Law Review
                  </li>
                  <ul>
                    <li>
                      Article published in Seattle University Law Review, Vol.
                      43.2
                    </li>
                  </ul>
                  <li>CALI Award for Highest Grade in Family Law</li>
                  <li>
                    <b>University of Washington,</b> Bachelor of Arts in
                    Philosophy and Law, Societies, & Justice, 2017
                  </li>
                </ul>
              </Col>
            </Row>
            <hr className="lawyer-divider"></hr>
            <Row>
              <Col xs={12} md={4}>
                <img
                  src={Marisa}
                  alt="Marisa J. Samuelson"
                  className="float-right"
                />
              </Col>
              <Col xs={12} md={8} className="lawyer-individual">
                <div title="Marisa J. Samuelson" className="lawyer-heading">
                  <h5>Marisa J. Samuelson</h5>
                  <h6>Associate Attorney</h6>
                  <a href="mailto:marisa@llllaw.net">marisa@llllaw.net</a>
                </div>
                <p>
                  My career has focused on serving the elderly. Before I
                  attended law school, I worked in healthcare, homecare
                  supervision, and non-profits dedicated to addressing elder
                  loneliness. I also attended Chapman University Fowler School
                  of law in which I worked at the Alona Cortese Elder Law Clinic
                  and helped low-income elderly in the Orange County region. At
                  Llewellyn & Shafer, I enjoy helping clients with a wide array
                  of legal issues.
                </p>
                <p>
                  Although I have lived in California and South Korea, I am
                  originally born and raised in Washington and enjoy spending
                  time with my husband, reading, exercising, and traveling.
                </p>
              </Col>
            </Row>
            {/* <Row>
                        <Col xs={12} md={4}>
                            <img src={LawyerIan} alt="IAN N. BOOTH" className="float-right" />
                        </Col>
                        <Col xs={12} md={8} className="lawyer-individual">
                            <div title="IAN N. BOOTH" className="lawyer-heading">
                                <h5>IAN N. BOOTH</h5>
                                <h6>Associate Attorney</h6>
                                <a href="mailto:Ian@llllaw.net">Ian@llllaw.net</a>
                            </div>
                            <p>I was admitted to the Washington bar in October 2018, but have worked at Llewellyn and Shafer, PLLC since January 2017. While in school at the Seattle University School of Law, I began working as an intern to assist with probate, estate planning, personal injury, and real estate matters, among many other practice areas. As of April 2019, I became an associate attorney at Llewellyn and Shafer, PLLC to assist clients during some of the most difficult and overwhelming experiences of their lives, so they don’t have to handle them alone. </p>
                            <p>I have lived in the Pacific Northwest my entire life, growing up in Poulsbo, WA, until moving to Seattle for my undergraduate studies at the University of Washington. In my spare time I enjoy music, basketball, and skiing. </p>
                        </Col>
                    </Row> */}
          </Container>
        </section>
      </>
    );
  }
}

export default Firm;
