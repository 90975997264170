import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Navbar from "./components/navbar";
import Home from "./components/home";
import Firm from "./components/firm";
import BusinessFormation from "./components/area_of_practice/business_formation";
import Footer from "./components/footer";
import "./scss/core.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import EstatePlanning from "./components/area_of_practice/estate_planning";
import Guardianship from "./components/area_of_practice/guardianships";
import PersonalInjury from "./components/area_of_practice/personal_injury";
import Probate from "./components/area_of_practice/probate";
import RealEstate from "./components/area_of_practice/real_estate";
import Finra from "./components/area_of_practice/finra";
import ElderLaw from "./components/area_of_practice/elder_law";
import Litigation from "./components/area_of_practice/litigation";
import Other from "./components/area_of_practice/other";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <>
        <Navbar></Navbar>
        {/* <Switch>
            <Route path={process.env.PUBLIC_URL + "/home" } component={Home}/>
            <Route path={process.env.PUBLIC_URL + "/firm" } component={Firm}/>
            <Route path={process.env.PUBLIC_URL + "/finra" } component={Finra}/>
            <Route path={process.env.PUBLIC_URL + "/elder_law" } component={ElderLaw}/>
            <Route path={process.env.PUBLIC_URL + "/business_formation" } component={BusinessFormation}/>
            <Route path={process.env.PUBLIC_URL + "/estate_planning" } component={EstatePlanning}/>
            <Route path={process.env.PUBLIC_URL + "/guardianships" } component={Guardianship}/>
            <Route path={process.env.PUBLIC_URL + "/personal_injury" } component={PersonalInjury}/>
            <Route path={process.env.PUBLIC_URL + "/probate"  }component={Probate}/>
            <Route path={process.env.PUBLIC_URL + "/real_estate" } component={RealEstate}/>
            <Route path="/" component={Home} exact />
          </Switch> */}
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/firm" component={Firm} />
          <Route exact path="/finra" component={Finra} />
          <Route exact path="/elder_law" component={ElderLaw} />
          <Route
            exact
            path="/business_formation"
            component={BusinessFormation}
          />
          <Route exact path="/estate_planning" component={EstatePlanning} />
          <Route exact path="/guardianships" component={Guardianship} />
          <Route exact path="/personal_injury" component={PersonalInjury} />
          <Route exact path="/probate" component={Probate} />
          <Route exact path="/real_estate" component={RealEstate} />
          <Route exact path="/litigation" component={Litigation} />
          <Route exact path="/other" component={Other} />

          <Route exact path="/" component={Home} />
        </Switch>
        <Footer></Footer>
      </>
    </BrowserRouter>
  );
}

export default App;
