import React, { Component } from "react";

class Other extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return <></>;
  }
}

export default Other;
