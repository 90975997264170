import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import CustomerForm from '../form';

class BusinessFormation extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render () {
        return(
            <>
                <section className="practice-header" id="business-formation-header">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col>
                                <h4>BUSINESS FORMATIONS</h4>
                                <p className="practice-quote">“I want to start a business.”</p>
                                <div>
                                    <h6>LLC</h6>
                                    <p>A Limited Liability Company (LLC) is a business entity that offers limited liability protection and pass-through taxation.</p>
                                    <div>
                                        <ul>
                                            <li>A LLC can be managed by either its members or by managers.</li>
                                            <li>As with corporations, the LLC legally exists as a separate entity from its owners.  Therefore, the owners cannot typically be held personally responsible for the debts and liabilities of the LLC.</li>
                                            <li>The LLC allows for pass-through taxation as its income is not taxed at the entity level; however, a tax return for the LLC must be completed.  Any income or loss of the LLC as shown on this return is passed through to the owner(s).  The owners, also called members, must then report the income or loss on their personal tax returns and pay any necessary tax.</li>

                                            <li>LLCs are often used to hold the assets of businesses, hold real estate used for rental, among several other reasons. </li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <h6>PLLC</h6>
                                    <p>PLLC stands for Professional Limited Liability Company. It essentially is the same as an LLC, except that it is owned and operated by members of the same professions, like doctors or accountants. </p>
                                    <div>
                                        <p>Advantages of a PLLC</p>
                                        <ul>   
                                            <li>A PLLC brings many advantages for professionals. One advantage is that the owners of the PLLC are shielded from liability; if the business is sued or if an employee is negligent, the owners’ assets are protected from the suit. Because professionals are vulnerable to negligence lawsuits, this benefit is especially important.</li>
                                            <li>A PLLC can also elect for pass-through tax status, meaning that only the owners will be taxed and not also the company itself. PLLCs can create retirement plans for employees with higher contribution limits that are available to sole proprietorships or partnerships.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <h6>Corporation</h6>
                                    <p>A corporation is a legal entity that is separate and distinct from its owners. Corporations enjoy most of the rights and responsibilities that individuals possess; they can enter contracts, loan and borrow money, sue and be sued, hire employees, own assets and pay taxes. Some refer to it as a "legal person."</p>
                                    <div>
                                        <p>How corporations work</p>
                                        <ul>
                                            <li>All kinds of businesses around the world are structured as corporations. While its exact legal status varies somewhat from jurisdiction to jurisdiction, a corporation’s most important aspect is limited liability. This means that shareholders may take part in the profits through dividends and stock appreciation but are not personally liable for the company’s debts.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <p>The creation of a corporation</p>
                                        <ul>
                                            <li>A corporation is created when it is incorporated by a group of shareholders who have ownership of the corporation, represented by their holding of common stock, to pursue a common goal. A corporation's goals can be for-profit or not, as with social clubs or charities. </li>
                                        </ul>
                                    </div>

                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="form-section">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col>
                                <CustomerForm />
                            </Col>
                        </Row>
                    </Container>
                </section>
                
            </>
        )
    }

}

export default BusinessFormation;