import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import CustomerForm from '../form';

class ElderLaw extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render () {
        return(
            <>
                <section className="practice-header" id="elder-law-header">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col >
                                <h4>ELDER LAW</h4>
                                <p>The primary focus of our office is Elder Law, which covers a broad spectrum of services, including but not limited to probate, estate planning, vulnerable adult protection actions, guardianship issues, and other general needs of an aging population. Discussing planning and end of life issues can be difficult, but we strive to conduct these matters in a professional, friendly, and comforting manner.</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="form-section">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col>
                                <CustomerForm />
                            </Col>
                        </Row>
                    </Container>
                </section>
                
            </>
        )
    }

}

export default ElderLaw;