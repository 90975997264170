import React, { Component } from 'react';
import Select from 'react-select';

class AreaOfPracticeSelection extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false
        }
    }
    render () {
        return (
            <>
                {/* <div id="area_of_practice_container" onMouseLeave={() => this.setState({open: false})} onClick={() => this.setState({open: true})} onMouseOver={() => this.setState({open: true})}> */}
                <div id="area_of_practice_container">
                    <label id="areas-of-practice-select-label" htmlFor="areas-of-practice-select">I would like help with </label>
                    <Select maxMenuHeight={500} className="area_selection" isSearchable={false} styles={this.props.styles} name="area of practice home selection" placeholder="" options={this.props.options} onChange={this.props.onChange} />
                </div>
            </>
        )
    }
}

export default AreaOfPracticeSelection;
