import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../assets/Logo.png";
import { NavLink, Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { FaBars } from "react-icons/fa";

export default class PageNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "/",
      navExpanded: false,
    };
  }

  handleSelect(key) {
    this.setState({
      activeKey: key,
      navExpanded: false,
    });
  }
  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  setNavClose = () => {
    this.setState({ navExpanded: false });
  };

  render() {
    return (
      <div id="navbar-container">
        <Navbar
          className="shadow-sm p-2 mb-5"
          onToggle={this.setNavExpanded}
          expanded={this.state.navExpanded}
          collapseOnSelect
          fixed="top"
          expand="md"
        >
          <Navbar.Brand to="/home" as={Link} id="logo-container">
            <div>
              <img src={Logo} alt="logo" id="logo-img" />
            </div>
            {/* <div className="logo-thing">
                            <p id="owner">Llewellyn & Shafer, PLLC.</p>
                            <p id="owner-title">Attorneys and Counselors at Law</p>
                        </div> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            {" "}
            <FaBars />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              activeKey={this.state.activeKey}
              onSelect={this.handleSelect}
              className="ml-auto"
            >
              <Nav.Link
                to="/home#welcome"
                as={NavHashLink}
                eventkey={process.env.PUBLIC_URL + "/home#welcome"}
                onClick={this.setNavClose}
              >
                Home
              </Nav.Link>
              <NavDropdown
                eventkey="areas"
                title="Areas of Practice"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  to="/business_formation"
                  as={NavLink}
                  eventkey="areas"
                  onClick={this.setNavClose}
                >
                  Business Formations
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/elder_law"
                  as={NavLink}
                  eventkey="areas"
                  onClick={this.setNavClose}
                >
                  Elder Law
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/estate_planning"
                  as={NavLink}
                  eventkey="areas"
                  onClick={this.setNavClose}
                >
                  Estate Planning
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/finra"
                  as={NavLink}
                  eventkey="areas"
                  onClick={this.setNavClose}
                >
                  FINRA
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/guardianships"
                  as={NavLink}
                  eventkey="areas"
                  onClick={this.setNavClose}
                >
                  Guardianships
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/real_estate"
                  as={NavLink}
                  eventkey="areas"
                  onClick={this.setNavClose}
                >
                  Real Estate
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/personal_injury"
                  as={NavLink}
                  eventkey="areas"
                  onClick={this.setNavClose}
                >
                  Personal Injury
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/probate"
                  as={NavLink}
                  eventkey="areas"
                  onClick={this.setNavClose}
                >
                  Probate
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/litigation"
                  as={NavLink}
                  eventkey="areas"
                  onClick={this.setNavClose}
                >
                  Litigation
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/other"
                  as={NavLink}
                  eventkey="areas"
                  onClick={this.setNavClose}
                >
                  Other
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                to="/firm"
                as={NavLink}
                eventkey={process.env.PUBLIC_URL + "/firm"}
                onClick={this.setNavClose}
              >
                Meet our Firm
              </Nav.Link>
              <Nav.Link
                to="/home#contact_us"
                as={NavHashLink}
                eventkey={process.env.PUBLIC_URL + "/home#contact_us"}
                onClick={this.setNavClose}
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
