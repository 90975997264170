import React, { Component, useState } from 'react';
import { Formik, Field } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SubmissionNotification from './submission_notification';


class CustomerForm extends Component {
    constructor(props){
        super(props);
        this.date=React.createRef();
        this.name=React.createRef();
        this.preferred_name=React.createRef();
        this.dob=React.createRef();
        this.spouse_name=React.createRef();
        this.spouse_preferred=React.createRef();
        this.spouse_dob=React.createRef();
        this.physical_home=React.createRef();
        this.physical_city=React.createRef();
        this.physical_state=React.createRef();
        this.physical_zip=React.createRef();
        this.mailing_home=React.createRef();
        this.mailing_city=React.createRef();
        this.mailing_state=React.createRef();
        this.mailing_zip=React.createRef();
        this.phone=React.createRef();
        this.email=React.createRef();
        this.preferred_phone=React.createRef();
        this.preferred_email=React.createRef();
        this.invoice_mail=React.createRef();
        this.invoice_email=React.createRef();
        this.needs=React.createRef();
        this.referrer=React.createRef();
        this.state = {
            show: false,
            preferred_contact: "phone"
        }
        this.setShow = this.setShow.bind(this);
        this.setPreferredContact = this.setPreferredContact.bind(this);
    }

    setShow(val) {
        this.setState({
            show:val
        });
    }
    setPreferredContact(event){
        var state = event.target.name === "preferred_phone" ? "phone" : "email"
        this.setState({
            preferred_contact: state
        });
    }


    render(){
        return (
            <>
                <div>
                    <h4>WE CAN HELP!</h4>
                    <p className="second-heading">Please complete our intake form. We will have one of our attorneys contact you as soon as they are available.</p>
                    <p>Completion of this intake form does not establish an attorney-client relationship, and this office does not represent you unless, and until, both you and the Attorney execute a written Fee Agreement.</p>
                </div>
                <Formik 
                    initialValues={{
                        date: "",
                        name: "",
                        preferred_name: "",
                        dob: "",
                        spouse_name: "",
                        spouse_preferred: "",
                        spouse_dob: "",
                        physical_home: "",
                        physical_city: "",
                        physical_state: "",
                        physical_zip: "",
                        phone: "",
                        email: "",
                        preferred_contact:"phone",
                        preferred_phone: true,
                        preferred_email: false,
                        needs: "",
                        referrer: ""
                    }}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        // When button submits form and form is in the process of submitting, submit button is disabled
                        setSubmitting(true);
                        var formData = new FormData();
                        var preferred_contact =  values.preferred_phone ? "phone" : "email"

                        formData.append("date", values.date)
                        formData.append("name", values.name)
                        formData.append("date of birth", values.dob)
                        formData.append("spouse name", values.spouse_name)
                        formData.append("spouse preferred name", values.spouse_preferred)
                        formData.append("spouse date of birth", values.spouse_dob)
                        formData.append("home address", values.physical_home)
                        formData.append("city", values.physical_city)
                        formData.append("state", values.physical_state)
                        formData.append("zip", values.physical_zip)
                        formData.append("phone", values.phone)
                        formData.append("email", values.email)
                        formData.append("contact me via", preferred_contact)
                        formData.append("legal needs", values.needs)
                        formData.append("referred by", values.referrer)

                        fetch('https://usebasin.com/f/15e74ad0b292', {
                            method: 'POST',
                            body: formData,
                            headers: {
                                Accept: "application/json"
                            }
                        })
                        .then(res => res.json())
                        .then(response => response)

                        // Resets form after submission is complete
                        resetForm();

                        this.setShow(true);

                        // Sets setSubmitting to false after form is reset
                        setSubmitting(false);
                    }}
                >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Row>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={3}>
                                    <Form.Label htmlFor="date">Date</Form.Label>
                                    <Form.Control style={{fontSize: "14px"}} type="date" ref={this.date} name="date" value={values.date} onChange={handleChange} onBlur={handleBlur} required />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={4}>
                                    <Form.Label htmlFor="name">Full Name</Form.Label>
                                    <Form.Control type="text" ref={this.name} name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} required />
                                </Col>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={4}>
                                    <Form.Label htmlFor="preferred_name">Preferred Name</Form.Label>
                                    <Form.Control type="text" ref={this.preferred_name} name="preferred_name" value={values.preferred_name} onChange={handleChange} onBlur={handleBlur} />
                                </Col>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={2}>
                                    <Form.Label htmlFor="dob">Date of Birth</Form.Label>
                                    <Form.Control style={{fontSize: "14px"}} type="date" ref={this.dob} name="dob" value={values.dob} onChange={handleChange} onBlur={handleBlur} />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={4}>
                                    <Form.Label htmlFor="spouse_name">Spouse Full Name</Form.Label>
                                    <Form.Control type="text" ref={this.spouse_name} name="spouse_name" value={values.spouse_name} onChange={handleChange} onBlur={handleBlur} />
                                </Col>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={4}>
                                    <Form.Label htmlFor="spouse_preferred">Spouse’s Preferred Name</Form.Label>
                                    <Form.Control type="text" ref={this.spouse_preferred} name="spouse_preferred" value={values.spouse_preferred} onChange={handleChange} onBlur={handleBlur} />
                                </Col>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={2}>
                                    <Form.Label htmlFor="spouse_dob">Spouse’s Date of Birth</Form.Label>
                                    <Form.Control style={{fontSize: "14px"}} type="date" ref={this.spouse_dob} name="spouse_dob" value={values.spouse_dob} onChange={handleChange} onBlur={handleBlur} />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={3}>
                                    <Form.Label htmlFor="physical_homee">Home Address (Physical)</Form.Label>
                                    <Form.Control type="text" ref={this.physical_home} name="physical_home" value={values.physical_home} onChange={handleChange} onBlur={handleBlur} />
                                </Col>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={3}>
                                    <Form.Label htmlFor="physical_city">City</Form.Label>
                                    <Form.Control type="text" ref={this.physical_city} name="physical_city" value={values.physical_city} onChange={handleChange} onBlur={handleBlur} />
                                </Col>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={2}>
                                    <Form.Label htmlFor="physical_state">State</Form.Label>
                                    <Form.Control type="text" ref={this.physical_state} name="physical_state" value={values.physical_state} onChange={handleChange} onBlur={handleBlur} />
                                </Col>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={2}>
                                    <Form.Label htmlFor="physical_zip">Zip</Form.Label>
                                    <Form.Control type="text" ref={this.physical_zip} name="physical_zip" value={values.physical_zip} onChange={handleChange} onBlur={handleBlur} />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={3}>
                                    <Form.Label htmlFor="phone">Phone Number</Form.Label>
                                    <Form.Control type="tel" ref={this.phone} name="phone" value={values.phone} onChange={handleChange} onBlur={handleBlur} required />
                                </Col>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={3}>
                                    <Form.Label htmlFor="email">E-mail</Form.Label>
                                    <Form.Control type="email" ref={this.email} name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} required />
                                </Col>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={3}>
                                    <Form.Label htmlFor="contact">Preferred Method of Contact</Form.Label>
                                    <Form.Group>
                                        <Form.Check inline label="Phone" type="radio" id="phone-form" ref={this.preferred_phone} name="preferred_phone" value={values.preferred_phone} onChange={this.setPreferredContact} onBlur={handleBlur} checked={this.state.preferred_contact === "phone"} />
                                        <Form.Check inline label="E-mail" type="radio" id="email-form" ref={this.preferred_email} name="preferred_email" value={values.preferred_email} onChange={this.setPreferredContact} onBlur={handleBlur} checked={this.state.preferred_contact === "email"} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={3}>
                                    <Form.Label htmlFor="referrer">Name of individual who referred you</Form.Label>
                                    <Form.Control type="text" ref={this.referrer} name="referrer" value={values.referrer} onChange={handleChange} onBlur={handleBlur} />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col className="col-custom" xs={12} md={12} lg={12} xl={8}>
                                    <Form.Label htmlFor="needs">Briefly describe your legal needs. Please don’t include any identifying information about other parties.</Form.Label>
                                    <Form.Control as="textarea" type="textarea" ref={this.needs} name="needs" value={values.needs} onChange={handleChange} onBlur={handleBlur} required />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col>
                                    <input type="hidden" name="_gotcha"></input>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Button className="submit-btn" type="submit" disabled={isSubmitting}>
                            Submit
                        </Button>
                    </Form>
                )}
                </Formik>
                <SubmissionNotification show={this.state.show} setShow={() => this.setShow(false)} />
            </>
        )
    }
}

export default CustomerForm;