import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import { FaCheck }from 'react-icons/fa';

class SubmissionNotification extends Component {
    
    render() {
        return (
            <Row>
              <Col xs={6}>
                <Toast className="toast" onClose={this.props.setShow} show={this.props.show} delay={3000} style={{ position: 'absolute', top: -30, right: -500, backgroundColor: "#4dd042", color:"#ffffffdb", fontSize: "16px" }} autohide>
                  <Toast.Header>
                    <FaCheck />
                    <strong className="mr-auto toast-header-text">  Submission Confirmation</strong>
                  </Toast.Header>
                  <Toast.Body>Your inquiry has been successfully submitted. We will get back to you shortly. Thank you!</Toast.Body>
                </Toast>
              </Col>
            </Row>
          );
    }
}

export default SubmissionNotification;