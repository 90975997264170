import React, { Component } from "react";
import AreaOfPracticeSelection from "./areas_of_practice_selection";
import { withRouter } from "react-router-dom";
import TestimonialCarousel from "./testimonial_carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope, FaFax } from "react-icons/fa";
class Home extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      options: [
        { value: "business_formation", label: "Business Formations" },
        { value: "elder_law", label: "Elder Law" },
        { value: "estate_planning", label: "Estate Planning" },
        { value: "finra", label: "FINRA" },
        { value: "guardianships", label: "Guardianships" },
        { value: "real_estate", label: "Real Estate" },
        { value: "personal_injury", label: "Personal Injury" },
        { value: "probate", label: "Probate" },
      ],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChange = (e) => {
    this.props.history.push(`${e.value}`);
  };
  render() {
    const selectStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#f6ae2d" : "#334786",
        textAlign: "left",
        cursor: "pointer",
        backgroundColor: !state.isSelected ? "#fff" : "#fdecce",
        "&:hover": {
          backgroundColor: "#fdecce !important",
        },
      }),
      container: (base) => ({
        ...base,
        width: "228px",
        display: "inline-block",
      }),
      control: (base, state) => ({
        ...base,
        height: 30,
        minHeight: 32,
        borderRadius: 0,
        width: "100%",
        textAlign: "left",
        cursor: "pointer",
        background: !state.isFocused ? "transparent" : "#fff",
        borderTop: "0px",
        borderLeft: "0px",
        borderRight: "0px",
        borderBottom: "3px solid #fff",
        paddingLeft: "0.5em",
        color: "#f6ae2d",
        fontFamily: "'Source Sans Pro', sans-serif",
      }),
      menu: (base) => ({
        ...base,
        marginTop: 5,
      }),
      placeholder: (base, state) => ({
        ...base,
        color: !state.isFocused ? "#f6ae2d" : "#334786",
        fontWeight: !state.isFocused ? "400" : "600",
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: "#ffff",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: "none",
      }),
      valueContainer: (base) => ({
        ...base,
        padding: 0,
        paddingLeft: 2,
      }),
    };

    const AreaPractice = withRouter(AreaOfPracticeSelection);
    return (
      <>
        <section id="welcome">
          <Container className="mx-auto p-4">
            <Row>
              <Col xs={12} className="text-center">
                <h2 id="law-firm-header">
                  Welcome to Llewellyn & Shafer, PLLC.
                </h2>
                <AreaPractice
                  styles={selectStyles}
                  onChange={this.onChange}
                  options={this.state.options}
                />
              </Col>
            </Row>
          </Container>
        </section>
        <section id="our_firm">
          <Container className="mx-auto p-4">
            <Row>
              <Col xs={12}>
                <h4 className="font-weight-bold">OUR FIRM</h4>
                <p>
                  The Law Offices of Llewellyn & Shafer, PLLC, serves the needs
                  of individuals, families and small businesses in Seattle and
                  throughout Washington State.
                </p>
                <p>
                  Our office offers devoted and comprehensive legal services,
                  including Elder Law, Probate, Estate Planning, Vulnerable
                  Adult Protection, Guardianship, Trust and Estate Litigation,
                  Appeals, Personal Injury, Investor Claims, Regulatory Matters
                  and Real Estate.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="testimonials">
          <Container className="testimonial__carousel_main mx-auto p-4">
            <Row>
              <Col xs={12} md={12} lg={12} className="mx-auto">
                <TestimonialCarousel />
              </Col>
            </Row>
          </Container>
        </section>
        <section id="contact_us">
          <Container className="mx-auto p-4">
            <Row>
              <Col xs={12} md={6} id="contact-us-info">
                <div className="content">
                  <h6>CONTACT US</h6>
                  <p>
                    <a href="mailto:info@llllaw.net">
                      <span>
                        <FaEnvelope />
                      </span>{" "}
                      info@llllaw.net
                    </a>
                  </p>
                  <p>
                    <a href="tel:206-923-2889">
                      <span>
                        <FaPhoneAlt />
                      </span>{" "}
                      (206) 923-2889
                    </a>
                  </p>
                  <p>
                    <span>
                      <FaFax />
                    </span>{" "}
                    (206) 923-2892
                  </p>
                  <p>
                    <a href="https://maps.google.com/?q=4847+California+Ave+SW+Suite+100+Seattle+WA+">
                      <a href="https://maps.apple.com/maps?q=4847+California+Ave+SW+Suite+100+Seattle+WA+">
                        <span>
                          <FaMapMarkerAlt />
                        </span>{" "}
                        4847 California Ave SW Suite 100{" "}
                      </a>
                    </a>{" "}
                    <span className="next-line">Seattle, WA 98116</span>
                  </p>
                </div>
                <div className="content">
                  <h6>OFFICE HOURS</h6>
                  <p className="hours-info">
                    <span className="contact_hours">Monday - Thursday:</span>9am
                    - 5pm
                  </p>
                  <p className="hours-info">
                    <span className="contact_hours">Friday:</span>9am - 12pm
                  </p>
                  <p className="appointment">Meetings by appointment only</p>
                </div>
              </Col>
              <Col xs={12} md={6} id="law-firm-location">
                <h6>DIRECTIONS</h6>
                <div className="i-frame-container">
                  <iframe
                    className="responsive-iframe"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2692.5220945576298!2d-122.38941688437124!3d47.55762937918158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549040fc7b697ffb%3A0x44f0c5d0fc94fd67!2sLaw%20Offices%20of%20John%20G.%20Llewellyn%2C%20PLLC!5e0!3m2!1sen!2sus!4v1604973524458!5m2!1sen!2sus"
                    width="450"
                    height="350"
                    frameBorder="0"
                    style={{ border: 0 }}
                    aria-hidden="false"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Home;
