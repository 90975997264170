import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import CustomerForm from '../form';

class Guardianship extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render () {
        return(
            <>
                <section className="practice-header" id="guardianship-header">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col>
                                <h4>GUARDIANSHIP</h4>
                                <p className="practice-quote">“I want to help a disabled person.”</p>
                                <p>Legal guardians are individuals that have legal authority to care for another person. Legal guardians must take care of personal and property interests. Legal guardianship is commonly used for: Incapacitated seniors, Developmentally disabled adults, and Minors.</p>
                                <p>Responsibilities of a legal guardian are broad. In most cases, the legal guardian also has legal and physical custody of the ward. This means that they must fulfill duties in the same way as a parent would for their child. These responsibilities may include:</p>
                                <ul>
                                    <li>Providing food.</li>
                                    <li>Purchasing clothing.</li>
                                    <li>Providing shelter for the ward.</li>
                                    <li>Maintaining the ward’s physical and emotional health.</li>
                                    <li>Protecting the ward from safety hazards.</li>
                                    <li>Maintain financial accounts, and distribute funds as needed.</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="form-section">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col>
                                <CustomerForm />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

export default Guardianship;