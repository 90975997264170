import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import CustomerForm from '../form';

class RealEstate extends Component{
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        return(
            <>
                <section className="practice-header" id="real-estate-header">
                <Container className="mx-auto p-4">
                    <Row>
                        <Col>
                            <h4>REAL ESTATE</h4>
                            <p className="practice-quote">“I want to buy/sell/transfer property.”</p>
                            <div>
                                <h6>Real Estate Purchase Contract</h6>
                                <p>A signed agreement for the sale or purchase of real property. It provides each party duties, responsibilities and rights regarding the sale or purchase of the real property. Real estate purchase contract disputes typically arise after the contract has been signed by both parties, but before the entire transaction has been completed.</p>
                            </div>
                            <div>
                                <h6>What are the conditions and terms of a Real Estate Purchase Contract?</h6>
                                <div>
                                    <p>Purchase agreements contain terms that both parties agree to follow and conditions that the parties agree to meet such as:</p>
                                    <ul>
                                        <li>Legal description of the property</li>
                                        <li>Purchase price</li>
                                        <li>Closing date</li>
                                        <li>Items included in the sale, such as appliances, carpeting, and lighting fixtures</li>
                                        <li>Items not included in the sale</li>
                                        <li>Clear title</li>
                                        <li>Condition of the property</li>
                                        <li>Type of deed being transferred</li>
                                        <li>Either party can breach the real estate contract if they fail to follow the conditions and terms of the contract.</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <h6>What is a dispute over closing costs?</h6>
                                <div>
                                    <p>A dispute over closing costs is a type of contract dispute that involves the negotiated price of the real estate property. The disputes that may arise include:</p>
                                    <ul>
                                        <li>Loan fraud</li>
                                        <li>Conflicts regarding good faith estimates</li>
                                        <li>Misrepresentation of contract terms</li>
                                        <li>Dispute over which local laws apply to the purchase contract</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <h6>What are some other real estate purchase contract disputes?</h6>
                                <div>
                                    <p>Other disputes typically involve failing to complete contingencies that must be met prior to the completion
        of the sale, such as:</p>
                                    <ul>
                                        <li>Getting a specific type of financing</li>
                                        <li>Inspecting the property prior to the buyer taking possession of it</li>
                                        <li>Buyer selling another property by a certain date</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                 </Container>
                </section>
                <section className="form-section">
                    <Container className="mx-auto p-4">
                    <Row>
                        <Col>
                            <CustomerForm />
                        </Col>
                    </Row>
                </Container>  
    
                </section>
            </>
        )
    }
}

export default RealEstate;