import React, { Component } from "react";

class Litigation extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return <></>;
  }
}

export default Litigation;
