import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import CustomerForm from '../form';

class Finra extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render () {
        return(
            <>
                <section className="practice-header" id="finra-header">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col>
                                <h4>Financial Industry Regulatory Authority (FINRA)</h4>
                                <p>Investors have a right to expect their stockbrokers and other financial advisors to recommend investments that are suitable to the requirements of the investor, not the ones that will make the broker the most money.</p>
                                <div>
                                    <h6>Overview of Securities Arbitration and Litigation</h6>
                                    <p>The term "securities" covers a number of different instruments including stocks, mutual funds, bonds, notes, debentures, investment contracts, treasury stocks and transferable shares. When investors lose money because of violations of securities laws by a company, broker-dealer or analyst, they can bring a private suit to hold the defendant civilly liable and recover damages. Litigation and arbitration over violations of federal and state securities laws can be complex, expensive and time consuming. It is important to discuss your situation and potential claims with a lawyer who has experience handling securities cases.  There are a variety of claims that private plaintiffs can bring under federal and state securities laws. Because of the complexity of these claims and securities laws in general, it is important to have an experienced securities attorney evaluate your situation.</p>
                                </div>
                                <div>
                                    <h6>Securities Arbitration</h6>
                                    <p>Arbitration is a form of alternative dispute resolution. Instead of your case being heard by a judge or jury in court, it is heard by a panel of one to three neutral arbitrators. The arbitrators will hear all the evidence and render a decision. In 1987, in Shearson v. McMahon, the U.S. Supreme Court held that agreements to submit securities disputes to arbitration were enforceable under the Federal Arbitration Act. Today, disputes between customers and broker-dealers are largely resolved in arbitration rather than in courts. Arbitration for these disputes is overseen by a self-regulatory organization such as the Financial Industry Regulatory Authority (FINRA) (formerly the National Association of Securities Dealers (NASD)).</p>
                                </div>
                                <div>
                                    <h6>Establishing a Securities Case</h6>
                                    <p>There are a number of factual, procedural and legal issues that a plaintiff must consider before deciding whether to file a lawsuit for a violation of the federal or state securities laws. Potential plaintiffs and their lawyers should consider issues such as the applicable statute of limitations, the evidence supporting their claims, potential defenses that the defendant could raise and damages.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="form-section">
                    <Container className="mx-auto p-4">
                    <Row>
                        <Col>
                            <CustomerForm />
                        </Col>
                    </Row>
                </Container> 
                </section>
            </>
        )
    }
}

export default Finra;