import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa'


function TestimonialCarousel(){
    return (
        <div className="p-2">
            <Carousel touch={true} className="carousel-fade" interval={null} prevIcon={ <FaAngleLeft />} nextIcon={<FaAngleRight />}>
                <Carousel.Item>
                    <div className="media mx-auto">
                        <div className="media-body text-center">
                            <blockquote className="blockquote border-0 p-0">
                                <p className="block-quote"><em>“This is placeholder text for a testimonial. This is what some of the clients has said. This extra sentence is just to add another line.”</em></p>
                                <footer className="blockquote-footer">
                                    <cite title="Sam Baudi">Sam Baudi</cite>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="media mx-auto">
                        <div className="media-body text-center">
                            <blockquote className="blockquote border-0 p-0">
                                <p className="block-quote"><em>“This is placeholder text for a testimonial. This is what some of the clients has said. This extra sentence is just to add another line.”</em></p>
                                <footer class="blockquote-footer">
                                    <cite title="Sam Baudi">Sam Baudi</cite>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                   
                </Carousel.Item>
                <Carousel.Item>
                    <div className="media mx-auto">
                        <div className="media-body text-center">
                            <blockquote className="blockquote border-0 p-0">
                                <p className="block-quote"><em>“This is placeholder text for a testimonial. This is what some of the clients has said. This extra sentence is just to add another line.”</em></p>
                                <footer className="blockquote-footer">
                                    <cite title="Sam Baudi">Sam Baudi</cite>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default TestimonialCarousel;