import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import CustomerForm from '../form';

class Probate extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        return(
            <>
                <section className="practice-header" id="probate-header">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col xs={12}>
                                <h4>PROBATE</h4>
                                <p className="practice-quote">“I have the will of someone who recently passed, what’s next?”</p>
                                <p>Probate is the court-supervised process of authenticating a last will and testament if the deceased made one. It includes locating and determining the value of the decedent's assets, paying his final bills and taxes, and, finally, distributing the remainder of the estate to his rightful beneficiaries. Listed below is a simplified explanation of the steps involved in probate.</p>
                                <div>
                                    <h6>Step 1 - Petition to Open Probate and Authenticating the Last Will and Testament</h6>
                                    <ul>
                                        <li>The first step is an application or petition to open probate of the estate and enter the will into probate. Sometimes it’s necessary to file the death certificate as well, along with the will and the petition.</li>
                                        <li>Completing and submitting the petition doesn’t have to be a daunting challenge. If the decedent left a will, and you have the original will, the judge will normally confirm that it is, in fact, valid. This typically involves a court hearing, and notice of the hearing must be given to all the beneficiaries listed in the decedent’s will as well as his heirs.</li>
                                    </ul>
                                </div>
                                <div> 
                                    <h6>Step 2 - Appointing the Executor or a Personal Representative </h6>
                                    <ul>
                                        <li>The judge will appoint an executor as well, also sometimes called a personal representative or administrator. This individual will oversee the probate process and settle the estate. This is likely the person that will be filing the petition to open probate, as well as requesting to be appointed as executor.</li>
                                        <li>The decedent’s choice for an executor is typically included in her will, but the court will appoint next of kin if they didn’t leave a will, typically their surviving spouse or an adult child. This individual isn’t obligated to serve—he can decline and the court will then appoint someone else. </li>
                                        <li>The appointed executor will receive “letters testamentary” or “letters of administration” from the court—a fancy, legal way of saying they receive documentation that allows them to act and enter into transactions on behalf of the estate.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h6>Step 3 - Locating the Decedent’s Assets</h6>
                                    <ul>
                                        <li>The executor's first task after being appointed involves locating and taking possession of all the decedent's assets so they can protect them during the probate process. The executor must hunt for any such assets, typically through a review of insurance policies, tax returns, and other documentation. </li>
                                    </ul>
                                </div>
                                <div>
                                    <h6>Step 4 - Determining Date of Death Values</h6>
                                    <ul>
                                        <li>Date of death values for the decedent's assets must be determined and this is generally accomplished through account statements and appraisals.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h6>Step 5 - Identifying and Notifying Creditors</h6>
                                    <ul>
                                        <li>The decedent’s creditors must be identified and notified of her death. </li>
                                        <li>Creditors typically have a limited period of time after receiving the notice to make claims against the estate for any money they’re owed.  The actions of the Personal Representative may positively or negatively affect how long the creditors have to file a claim.</li>
                                        <li>The executor can reject claims if she has reason to believe they’re not valid. The creditor might then petition the court to have a probate judge decide whether the claim should be paid.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h6>Step 6 - Paying the Decedent’s Debts</h6>
                                    <ul>
                                        <li>Next, those creditor claims are paid. The executor will pay all the decedent's debts and his final bills, including those that might have been incurred by his final illness, from estate funds.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h6>Step 7 - Preparing and Filing Tax Returns</h6>
                                    <ul>
                                        <li>The executor will file the decedent’s final personal income tax returns for the year in which he died. She’ll determine if the estate is liable for any estate taxes, and, if so, she’ll file these tax returns as well. Any taxes due are paid from estate funds.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h6>Step 8 - Distribute and Close Estate</h6>
                                    <ul>
                                        <li>When all these steps have been completed, the executor can petition the court for permission to distribute what is left of the decedent’s assets to the beneficiaries named in his will. This will usually involve accounting for all the actions the executor made on behalf of the estate.</li>
                                        <li>After everything above has been completed, the Executor will file a Declaration of Completion stating that all essential functions of the executor on the behalf of the estate have been completed. If after 30-days from the filing of the Declaration of Completion no beneficiary challenges the administration of the estate, probate is closed and your duties as executor are completed.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="form-section">
                    <Container className="mx-auto p-4">
                        <Row>
                            <Col xs={12}>
                                <CustomerForm />
                            </Col>
                        </Row>
                    </Container>  
                </section>
            </>
        )
    }
}

export default Probate;